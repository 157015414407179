import React from 'react'

const PayPal = () => {

    return (
        <div>
        </div> 
    )
}

export default PayPal;