import React from 'react'
import Layout from "../components/App/Layout"
import PageBannerBackground from '../components/Common/PageBannerBackground'
import PayPal from "../components/App/PayPal"

const Pay = () => {
    return (
        <Layout page="Pay">
            <PageBannerBackground
                pageTitle="MAKE PAYMENT"
                crumbs={[{page: "Home", url: "/"}, {page: "More"}]}  
                bgFileName="events/upcoming_events.jpg" 
            />
            <PayPal />
        </Layout>
    );
}

export default Pay